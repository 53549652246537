<!--库存监控-->
<template>
    <div class="inventoryMonitor">
        <div class="header">
            <div class="back-btn" @click="$parent.hide()">
                <i class="iconfont iconchehui"></i>
            </div>
            <span class="title">
                实时库存监控
            </span>
        </div>
        <div class="monitor-content">
            <div class="monitor-dynamic monitor-panel">
                <div class="top">
                    <div class="title">
                        当日原材料消耗动态
                    </div>
                    <div class="query-box">
                        <el-date-picker v-model="timeDate" @change="updateData" value-format="yyyy-MM-dd"></el-date-picker>
                        <el-select v-model="stationId" @change="updateData">
                            <el-option
                                v-for="item in stationList"
                                :key="item.station_id"
                                :label="item.name"
                                :value="item.station_id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="right-icon"></div>
                </div>
                <div class="line-list">
                    <div
                        v-for="item in lineList"
                        :key="item.line_code"
                        @click="lineChange(item.line_name)"
                        :class="{head_target: true, active: checkLine === item.line_name}"
                    >
                        {{ item.line_name }}
                    </div>
                    <!-- <el-radio-group v-model="checkLine" @change="lineChange">
                        <el-radio
                            v-for="item in lineList"
                            :key="item.line_code"
                            :label="item.line_name"
                        >
                            {{ item.line_name }}
                        </el-radio>
                    </el-radio-group> -->
                </div>
                <div class="monitor-data">
                    <div class="table-box">
                        <div class="output-table">
                            <table cellpadding="1" cellspacing="1">
                                <tr>
                                    <th :colspan="(curTotalConsume.names||[]).length+2" class="tb-title">
                                        产量
                                        <div class="bottom_line"></div>
                                    </th>
                                </tr>
                            </table>
                            <table cellpadding="1" cellspacing="1">
                                <tr>
                                    <th></th>
                                    <td v-for="name in curTotalConsume.names" :key="name">
                                        {{ name }}
                                    </td>
                                    <td>
                                        年合计
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        本日产量
                                    </th>
                                    <th v-for="(val,index) in curTotalConsume.dayList" :key="'day'+index">
                                        {{ val }}
                                    </th>
                                    <td rowspan="2">
                                        {{ curTotalConsume.yearTotal }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        月累计量
                                    </th>
                                    <th v-for="(val,index) in curTotalConsume.monthList" :key="'month'+index">
                                        {{ val }}
                                    </th>
                                </tr>
                            </table>
                        </div>
                        <div class="material-table">
                            <table class="tb-header" cellpadding="1" cellspacing="1">
                                <tr>
                                    <th colspan="6" class="tb-title">
                                        原材料进出纪录
                                        <div class="bottom_line"></div>
                                    </th>
                                </tr>
                                <tr>
                                    <th>原材料名称</th>
                                    <th>上日库存</th>
                                    <th>本日采购量</th>
                                    <th>本日实时消耗</th>
                                    <th>本日实时单耗</th>
                                    <th>本日实时库存</th>
                                </tr>
                            </table>
                            <div class="table-list">
                                <table cellpadding="1" cellspacing="1">
                                    <tr
                                        v-for="(item,index) in materialRecord"
                                        :key="'record'+index"
                                        :class="{'active':item.typecode==checkedMaterialRecord.typecode}"
                                        @click="materialRecordChange(item)"
                                    >
                                        <th>{{ item.typename }}</th>
                                        <td>{{ item.kc_s }}</td>
                                        <td>{{ item.grsl }}</td>
                                        <td>{{ item.yl }}</td>
                                        <td>{{ item.dh }}</td>
                                        <td>{{ item.kc }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="charts-panel">
                        <div class="charts-box">
                            <div class="top top1">
                                <div class="title">
                                    本日实时消耗（{{ checkedMaterialRecord.typename }}）
                                </div>
                            </div>
                            <div id="day-use-chart" class="chart"></div>
                        </div>
                        <div class="charts-box">
                            <div class="top top1">
                                <div class="title">
                                    本日实时单耗（{{ checkedMaterialRecord.typename }}）
                                </div>
                            </div>
                            <div id="day-single-use-chart" class="chart"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="stock-panel monitor-panel">
                <stockMonitorPanel
                    ref="stockMonitor"
                ></stockMonitorPanel>
            </div>
        </div>
    </div>
</template>

<script>
import stockMonitorPanel from './stockMonitorPanel';
import utils from '@/utils/util';
export default {
    name: 'inventory-monitor',
    components: { stockMonitorPanel },
    data() {
        return {
            // 日期
            timeDate: '',
            // 场站
            stationId: '',
            stationList: [],

            // 选中得线路
            checkLine: '',
            // 线路列表
            lineList: [],
            // 总消耗数据所有线路
            totalConsumes: [],
            // 当前线路下总消耗数据
            curTotalConsume: {
                names: [],
                dayList: [],
                monthList: [],
            },
            // 原材料进出记录
            materialRecord: [],
            // 当前选中得原材料进出记录
            checkedMaterialRecord: {},
        };
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.initCharts();
        });
        this.initData();
    },
    methods: {
        // 初始化操作
        async initData() {
            this.timeDate = utils.currentTimeFormat('yyyy-MM-dd');
            await this.getStationData();
            if (this.stationList.length > 0) {
                this.stationId = this.stationList[0].station_id;
            }
            this.updateData();
        },
        updateData() {
            this.getTotalConsumes();
            this.getMaterialRecord().then(() => {
                this.initCharts();
            });
        },
        // 线路变化
        lineChange(line) {
            this.checkLine = line;
            this.curTotalConsume = this.totalConsumes[line] || {
                names: [],
                dayList: [],
                monthList: [],
            };
        },
        materialRecordChange(item) {
            this.checkedMaterialRecord = item;
            this.initCharts();
        },
        // 获取站点数据
        async getStationData() {
            this.stationList = [];
            const station_Names = this.$takeTokenParameters('StationNames').split(',');
            const station_IDs = this.$takeTokenParameters('StationIds').split(',');
            if ((station_Names.length || []) > 0) {
                station_Names.forEach((item, index) => {
                    this.stationList.push({
                        name: item,
                        station_id: station_IDs[index],
                    });
                });
            }
        },

        // 获取消耗总数居 所有线路
        async getTotalConsumes() {
            try {
                const res = await this.$axios
                    .get(`/interfaceApi/report/yclxh/getconsumesbydate/total?date=${this.timeDate}&station_id=${this.stationId}` );
                this.lineList = [...res.list] || [];
                if (this.lineList.length > 0) {
                    this.checkLine = this.lineList[0].line_name;
                }
                this.$refs.stockMonitor.updateData(this.lineList, this.stationId);
                const totalConsumes = res.results || [];
                const consumesResult = {}; // 处理结果 这里数据需要格式化下
                totalConsumes.forEach(item => {
                    const names = [];
                    const dayList = [];
                    const monthList = [];
                    let yearTotal = 0.0;
                    if (item.daylist.length > 0) {
                        const dayMapping = {};
                        item.daylist.forEach(dayItem => {
                            dayMapping[dayItem.strength_grade_name] = dayItem;
                        });
                        // 使monthList 数据顺序和 dayList 一致
                        item.monthlist.forEach(monthItem => {
                            names.push(monthItem.strength_grade_name);
                            monthList.push(monthItem.cl);
                            const dayData = dayMapping[monthItem.strength_grade_name] || {};
                            dayList.push(dayData.cl || '');

                            yearTotal += monthItem.cl;
                        });
                    }
                    consumesResult[item.key] = {
                        names: names, // 名称
                        dayList: dayList, // 本日产量
                        monthList: monthList, // 本月产量
                        yearTotal: yearTotal.toFixed(2), // 本年合计
                    };
                });
                this.totalConsumes = consumesResult;
                this.curTotalConsume = consumesResult[this.checkLine] || {};
            } catch (e) {
                if (e.ErrorCode) {
                    this.$message.error(e.ErrorCode.Message);
                }
            }
        },
        // 原材料进出记录
        async getMaterialRecord() {
            try {
                const res = await this.$axios
                    .get(`/interfaceApi/report/yclxh/getstockconsumesbydate/date?date=${this.timeDate}&station_id=${this.stationId}`);
                this.materialRecord = res || [];
                if ( this.materialRecord.length > 0) {
                    this.checkedMaterialRecord = this.materialRecord[0];
                }
            } catch (e) {
                if (e.ErrorCode) {
                    this.$message.error(e.ErrorCode.Message);
                }
            }
        },
        // 本日实时消耗
        async getDayChartDataNow() {
            try {
                const res = await this.$axios
                    .get('/interfaceApi/report/yclxh/getstockrealtimexbydate/date'
                        + `?date=${this.timeDate}&station_id=${this.stationId}&typecode=${this.checkedMaterialRecord.typecode}`);
                const chartData = {
                    names: [],
                    values: [],
                };
                (res || []).forEach(item => {
                    chartData.names.push(item.strength_grade_name);
                    chartData.values.push(item.yl);
                });
                this.drawHillChart('day-use-chart', chartData);
            } catch (e) {
                if (e.ErrorCode) {
                    this.$message.error(e.ErrorCode.Message);
                }
            }
        },
        // 本日实时单耗
        async getDayChartDataSingle() {
            try {
                const res = await this.$axios
                    .get('/interfaceApi/report/yclxh/getstockrealtimedbydate/date'
                    + `?date=${this.timeDate}&station_id=${this.stationId}&typecode=${this.checkedMaterialRecord.typecode}`);
                const chartData = {
                    names: [],
                    values: [],
                };
                (res || []).forEach(item => {
                    chartData.names.push(item.strength_grade_name);
                    chartData.values.push(item.dh);
                });
                this.drawHillChart('day-single-use-chart', chartData);
            } catch (e) {
                if (e.ErrorCode) {
                    this.$message.error(e.ErrorCode.Message);
                }
            }
        },
        initCharts() {
            this.getDayChartDataNow();
            this.getDayChartDataSingle();
        },
        drawHillChart(id, opt) {
            const myChart = this.$echarts.init(document.getElementById(id));
            myChart.resize();
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none',
                    },
                    formatter: function (params) {
                        return params[0].name + ': ' + params[0].value;
                    },
                },
                xAxis: {
                    data: opt.names,
                    axisTick: { show: true },
                    axisLine: { show: true },
                    axisLabel: {
                        color: '#979EA7',
                    },
                    min: -1,
                },
                yAxis: {
                    name: '(吨)',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: 'rgba(255,255,255,0.1)',
                        },
                    },
                    axisTick: { show: false },
                    axisLine: { show: true },
                    axisLabel: {
                        show: true,
                        color: '#979EA7',
                    },
                },
                grid: {
                    top: '15%',
                    left: '15%',
                    right: '3%',
                    bottom: '10%',
                    containLabel: true,
                },
                color: ['#76E0A8'],
                series: [{
                    name: 'hill',
                    type: 'pictorialBar',
                    barCategoryGap: '-50%',
                    symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                    itemStyle: {
                        opacity: 0.5,
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: '#76E0A8', // 0% 处的颜色
                            }, {
                                offset: 1, color: '#13BD63', // 100% 处的颜色
                            }],
                            global: false, // 缺省为 false
                        },

                    },
                    emphasis: {
                        itemStyle: {
                            opacity: 1,
                        },
                    },
                    data: opt.values,
                    z: 10,
                }],
            };
            myChart.setOption(option, true);
        },
    },
};
</script>

<style lang="stylus" scoped>
.inventoryMonitor
    width 100%
    height 100%
    background #0E1C31
    ::-webkit-scrollbar
        width: 0.02rem /*高宽分别对应横竖滚动条的尺寸*/;
        height: 0.05rem;
        opacity .5
    ::-webkit-scrollbar-track
        background transparent
    .header
        height 0.61rem
        background url('./../../../assets/images/leadingCockpit/top.png') center no-repeat
        background-size auto 100%
        text-align center
        position relative
        .back-btn{
            position absolute
            padding-right 0.1rem;
            width: 0.63rem;
            height: 0.31rem;
            background rgba(63, 116, 190, 0.2)
            border-bottom-right-radius 90%;
            color #2799C7;
            cursor pointer
        }
        .title
            font-size 0.24rem
            font-family Source Han Sans CN
            font-weight bold
            line-height 0.56rem
            background linear-gradient(0deg, #00A8FF 0%, #00EAFF 100%)
            -webkit-background-clip text
            -webkit-text-fill-color transparent
    .monitor-content
        box-sizing border-box
        width 100%;
        height calc(100% - 0.61rem);
        padding 0.14rem;
        display flex
        justify-content space-between
        .monitor-panel
            width 49.9%;
            height 100%;
            box-shadow: 0.02rem 0.02rem 0.23rem 0rem rgba(5,169,244,0.2), -0.02rem -0.02rem 0.23rem 0rem rgba(5,169,244,0.2);
            border: 1px solid #0D53A1;
            .top1
                background url("./../../../assets/images/leadingCockpit/titleS.png") no-repeat !important
                height 0.56rem !important
                padding-left 0.3rem !important
            .top
                width 100%;
                height 0.46rem;
                padding-left 0.2rem;
                display flex
                justify-content space-between
                align-items center
                background #11213B
                position relative
                background url("./../../../assets/images/leadingCockpit/title.png") no-repeat
                background-size 100% 100%;
                .query-box
                    padding-right 1.1rem
                    margin-top .13rem
                .right-icon
                    position absolute
                    right 0.1rem;
                    top 0.2rem;
                    width 1rem;
                    height 0.22rem;
                    background url("../images/nl.png") no-repeat
                    background-size 100% 100%;
                .title
                    font-size 0.16rem;
                    color #fff;
                    margin-left .24rem
                    .iconfont
                        color #32C5FF
                        margin-right 0.24rem;
                >>> .el-input
                    width: 1.9rem!important;
                    margin-right 0.15rem;
                    .el-input__inner
                        width: 2rem
                        height: 0.3rem;
                        background: #102039;
                        border-radius: 0.02rem;
                        border: 1px solid #294169;
                        font-size 0.12rem;
                        color rgba(255,255,255,0.7)
        .monitor-dynamic
            .line-list
                display flex
                padding .1rem
                height .6rem
                div
                    color #999999
                    background rgba(0,171,255,0.12)
                    font-size 0.16rem
                    width 1rem
                    margin-left: .1rem;
                    height .4rem
                    display flex
                    justify-content space-around
                    align-items center
                    cursor pointer
                    font-family Source Han Sans CN
                .active
                    color #fff !important
                    background rgba(0,171,255,0.12) !important
                    border 1px solid #00ABFF !important
                // width 100%;
                // height 0.44rem;
                // display flex
                // align-items center
                // padding-left: 0.2rem;
                // .el-radio-group
                //     width 100%;
                //     display flex
                //     .el-radio
                //         display flex
                //         align-items center
                //         >>> .el-radio__label
                //             color: rgba(255,255,255,0.6)
                //         >>> .el-radio__input.is-checked+.el-radio__label
                //             color: #32C5FF;
                //         >>> .el-radio__input.is-checked .el-radio__inner
                //             border-color: #32C5FF
                //             background: #32C5FF
                //         >>> .el-radio__inner
                //             width 0.12rem
                //             height 0.12rem
                //             margin auto
                //             background none;
                //             border: 1px solid rgba(255,255,255,0.6)
            .monitor-data
                width 100%;
                height calc(100% - 0.9rem)
                .table-box
                    height 53%
                    table
                        table-layout fixed
                        width 100%
                        /*border-collapse:collapse;*/
                        border:none;
                        th
                            font-size 0.12rem;
                            font-weight normal
                            color #fff
                        td
                            font-size 0.14rem;
                            color #fff
                            font-family technology
                        th,td
                            text-align center
                            padding 0.03rem 0;
                            border none
                            border-collapse collapse
                            box-shadow: 1px 1px 10px 0px #003062 inset;
                            font-family: PingFangSC-Regular
                    .output-table
                        height 29%;
                        table th
                            width 1rem;
                    .material-table
                        height 71%;
                        table
                            padding-right: 0.03rem;
                            tr
                                td
                                    color #32C5FF
                                    font-family PingFangSC-Regular
                                    cursor pointer
                        .tb-header
                            height 20%;
                        .table-list
                            height 80%;
                            overflow-y auto
                            tr
                                &:hover,&.active
                                    th,td
                                        box-shadow: 0 0 0.03rem 0 #FFA800;
                                    td
                                        color #FFA800
                .charts-panel
                    width 100%;
                    height 46%
                    display flex
                    justify-content space-between
                    .charts-box
                        width 49.5%;
                        height 100%;
                        .chart
                            width 100%;
                            height calc(100% - 0.46rem);
    .tb-title
        position relative
        .bottom_line
            width: .6rem;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: calc(50% - .3rem);
            background-image: -webkit-gradient(linear, left top, right top, from(#003969), color-stop(#00fbff), to(#003969));
            background-image: linear-gradient(to right, #003969, #00fbff, #003969);

</style>
